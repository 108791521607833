import { breakpoints, g } from '@pretto/bricks/components/layout'
import * as typo from '@pretto/bricks/core/typography'
import RichContent from '@pretto/bricks/website/about/components/RichContent'

import styled, { css } from 'styled-components'

export const Content = styled.div`
  display: none;

  @media screen and (min-width: ${breakpoints.tablet}) {
    display: flex;
  }
`
export const TabMenu = styled.div`
  margin-right: ${g(5)};
`
export const Divider = styled.div`
  width: 1px;
  height: auto;
  background: ${({ theme }) => theme.colors.neutral3};
  margin-right: ${g(5)};
`
export const TabContent = styled(RichContent)`
  ${typo.bodyBook16}

  ${({ $isSelected }) =>
    !$isSelected &&
    css`
      display: none;
    `}
`
export const TabOption = styled.div`
  display: flex;
  align-items: center;
  padding: ${g(1)} 0px;
  min-width: ${g(25)};
  max-width: ${g(37)};

  & + & {
    margin-top: ${g(2)};
  }
`
export const TabBullet = styled.div`
  border-radius: ${g(1)};
  height: ${g(1)};
  width: ${g(1)};
  background-color: ${({ theme }) => theme.colors.neutral1};
  margin-right: ${g(2)};
  flex-shrink: 0;
`
export const TabTitle = styled.div`
  ${typo.button18}
  cursor: pointer;

  ${({ $isSelected }) =>
    $isSelected &&
    css`
      text-decoration: underline;
      color: ${({ theme }) => theme.colors.primary2};
    `}
`

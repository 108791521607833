import { ARROW_DOWN, ARROW_LEFT, ARROW_RIGHT, ARROW_UP } from '@pretto/bricks/core/constants/keycodes'

import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'

import * as S from './styles'

const Content = ({ allowedTypes, tabs }) => {
  const [focusIndex, setFocusIndex] = useState(null)
  const [tabSelectedIndex, setTabSelectedIndex] = useState(0)
  const tabRef = useRef([])

  useEffect(() => {
    if (focusIndex === null) {
      return
    }
    tabRef.current[focusIndex].focus()
    setTabSelectedIndex(focusIndex)
  }, [focusIndex])

  const handleTabRef = (i, node) => {
    tabRef.current[i] = node
  }

  const toggleTab = i => {
    setTabSelectedIndex(i)
  }

  const indexMin = 0
  const indexMax = tabs.length - 1

  const nextTab = () => {
    const nextIndex = focusIndex + 1
    setFocusIndex(nextIndex > indexMax ? indexMin : nextIndex)
  }

  const previousTab = () => {
    const previousIndex = focusIndex - 1
    setFocusIndex(previousIndex < indexMin ? indexMax : previousIndex)
  }

  const changeTabFocus = e => {
    switch (e.keyCode) {
      case ARROW_DOWN:
      case ARROW_RIGHT:
        nextTab()
        break
      case ARROW_UP:
      case ARROW_LEFT:
        previousTab()
        break
      default:
    }
  }

  return (
    <S.Content>
      <S.TabMenu role="tablist" aria-label="tabs">
        {tabs.map(({ tabTitle }, i) => {
          const isSelected = i === tabSelectedIndex
          const isTabbable = i === focusIndex || (focusIndex === null && i === 0)

          return (
            <S.TabOption role="tab" key={i} aria-selected={isSelected}>
              <S.TabBullet />
              <S.TabTitle
                $isSelected={isSelected}
                id={`tab-${i}`}
                onClick={toggleTab.bind(null, i)}
                onKeyDown={changeTabFocus}
                ref={handleTabRef.bind(null, i)}
                tabIndex={isTabbable ? '0' : '-1'}
              >
                {tabTitle}
              </S.TabTitle>
            </S.TabOption>
          )
        })}
      </S.TabMenu>
      <S.Divider />
      {tabs.map((tab, i) => (
        <S.TabContent key={i} allowedTypes={allowedTypes} $isSelected={tabSelectedIndex === i}>
          {tab.tabContent}
        </S.TabContent>
      ))}
    </S.Content>
  )
}

Content.propTypes = {
  allowedTypes: PropTypes.array.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      tabContent: PropTypes.string.isRequired,
      tabTitle: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
}

export default Content
